

.std-header {
	color: white;
	background-color: fade(@blue-top-menu, 70%);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;

	
	.logo {
		color: white;
		text-decoration: none;
		font-size: 0;
		align-self: center;
		img {
			display: block;
		}
	}
	
	.menu {
		list-style: none;
		li {
			margin-left: 10px;
			// padding-bottom: 10px;
			a {
				.grave(20);
				color: white;
				text-transform: uppercase;
				text-decoration: none;
				font-weight: 800;
				padding-left: 10px;
				padding-right: 10px;
				// padding-bottom: 6px;	
				// padding-top: 2px;
			}
		}
		& > li:last-child {
			& > a {
				padding-right: 0;
			}
		}
	}
	
	.global-back {
		a {
			.grave(10);
			display: inline-block;
			position: relative;
			color: white;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: 800;
			top: -8px;
			font-weight: 400;
		}
	}

	.desktop({
		.container {
			display: grid;
			grid-template-columns: auto 1fr;
			min-height: 100%;

		}
	
		nav {
			justify-self: right;
			align-self: center;
			text-align: right;

		}
		.menu {
			display: inline-block;
			margin: 0;
			padding: 0;
			& > li {
				display: inline-block;
				position: relative;
				a {
					.grave(12);
				}
				
				ul {
					position: absolute;
					top: 100%;
					left: -10000px;
					opacity: 0;
					transition: opacity .3s;
					color: white;
					text-align: left;
					list-style: none;
					font-size: 70%;
					// width: 300px;
					
					li {
						display: block;
						background-color: @blue-text;
						padding: 0;
						a {
							display: block;
							padding: 8px 20px;
							white-space: nowrap;
						}
						
						&:hover {
							background-color: @tan-button;
						}
					}

				}

				&:hover {
					ul {
						left: 50%;
						opacity: 1;
						transform: translateX(-50%);
						padding-top: 29px;
						padding-left: 0;
						margin-left: 0;
					}
					
					&:after {
						transform: rotate(-90deg);
					}
				}
				
				&.has-children {
					padding-right: 20px;
				}
				&.has-children:after {
					content: url('/svg/keyboard-arrow-down-white.svg');
					position: absolute;
					right: 0px;
					// bottom: 10px;
					height: 24px;
					transform-origin: center;
					transition: transform .3s;
				}
				
			}
		}
		
		.global-back + .menu {
			& > li {
				&:hover {
					ul {
						padding-top: 18px;
					}
				}
			}
		}
		.tribar {
			display: none;
		}
	});
	.desktop-and-smaller({
		background-color: @blue-top-menu;
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: black; 
			.menu {
				padding: 0;
				& > li {
					display: block;
					
					& > a {
						font-weight: 600;
					}
					
					& > ul {
						position: relative;
						left: auto;
						opacity: 1;
						list-style: none;
						margin: 20px 0 10px 15px;
						padding-left: 0;
						
						a {
						}
					}
				}
			}
			
			&.open {
				left: 0;
				opacity: 1;
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: white;
			}
			.two {
				margin: 7px 0;
			}
			
		}
		.logo {
			img {
				max-width: 80%;
				padding-top: 15px;
			}
		}
	});

}

header {
	.std-header;
	
	box-sizing: border-box;
	height: 83px;
}

.jumbo {
	overflow: hidden;
	color: white;

	picture img {
		object-position: left center;
	}
	
	.container-wrapper {
		position: relative;
		height: 100%;
		padding-left: 40px;
		padding-right: 40px;
		display: flex;

		.container {
			align-self: end;
			width: 100%;
			margin: 0 auto clamp(40px, 4vw, 200px) auto;
		}
	}
	
	h1 {
		.aviano(70);
		margin: 0;
		line-height: 1.1em;
		font-size: 3.8vw;
	}
	
	h2 {
		.myriad(16);
		margin: 30px auto 0 0;
		max-width: @container-width / 2;
	}
	
	.desktop-and-smaller({
		h2 {
			font-size: clamp(12px, 1.45vw, 1.45vw);
			margin-top: .8em;
		}
	});
	.tablet-and-smaller({
		h1 {
			br {
				display: none;
			}
		}
	});
}

.page-inner {
	.jumbo {
		aspect-ratio: 2.5;
		
		&.short {
			aspect-ratio: 4;
		}
	}
	.desktop-and-smaller({
		padding-top: 83px;
	});
	
	.msg-and-pic {
		.msg {
			.inner {
				padding-top: 80px;
				padding-bottom: 80px;
			}

			h2 {
				.grave(28, 600);
				text-transform: uppercase;
				margin-top: 0;
			}
		}
	}
}

.page-landing {
	.jumbo {
		aspect-ratio: 2.73255814;
	}
	.desktop-and-smaller({
		padding-top: 83px;
	});
}

footer {
	background-color: @blue-text;
	color: white;

	padding-top: 70px;
	padding-bottom: 40px;
	
	a {
		text-decoration: none;
		color: white;
	}
	
	.container {
		display: grid;
		row-gap: 40px;
		
		.desktop({
			grid-template-columns: 2fr 3fr 2fr;
			column-gap: 40px;
			
			.copyright {
				grid-column: span 3;
			}
			.third {
				.menu {
					text-align: right;
				}
				
				.contact {
					text-align: right;
				}
			}
		});
		
		.logo {
			img {
				max-width: 100%;
			}
		}
		
		.msg {
			.myriad(12);

			p:first-child {
				margin-top: 0;
			}
		}
		
		.menu {
			list-style: none;
			padding-left: 0;
			margin-top: 0;

			li {
				display: inline-block;
				margin-left: 10px;
			}
			a {
				.grave(10);
			}
		}
		
		.copyright {
			text-align: center;
		}
		
		.third {
			.menu {
				& > li:first-child {
					margin-left: 0;
				}
				& > li:last-child {
					margin-right: 0;
				}
			}
			
			.contact {
				.myriad(12);
				
				a {
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	
	.desktop-and-smaller({
		.logo {
			text-align: center
		}

	});
}

.news {
	color: black;
	padding-top: 60px;
	padding-bottom: 60px;

	h1 {
		.grave(37);
		margin-top: 0;
		margin-bottom: 0px;
	}
}

.svg-icons {
	position: absolute;
	left: -10000px;
	top: 0;
	height: 0;
}

.page-maintenance {
	header {
		background-color: fade(@red-maint, 70%);
	}
	.desktop({
		.menu {
			& > li {
				ul {
					li {
						background-color: fade(@red-maint, 70%);
						&:hover {
							// background-color: @tan-button;
						}
					}
				}
			}
		}
	});

	footer {
		background-color: @red-maint;
	}
	.team-members {
		.team-cell {
			.info {
				background-color: fade(@red-maint, 70%);
			}
		}
	}	
	.desktop-and-smaller({
		header {
			background-color: @red-maint;
		}
	});
	
}
.page-ground-services {
	header {
		background-color: fade(@purple-footer, 70%);
	}
	.desktop({
		.menu {
			& > li {
				ul {
					li {
						background-color: fade(@purple-footer, 70%);
						&:hover {
							// background-color: @tan-button;
						}
					}
				}
			}
		}
	});
	footer {
		background-color: @purple-footer;
	}
	.team-members {
		.team-cell {
			.info {
				background-color: fade(@purple-button, 70%);
			}
		}
	}	
	.msg-and-pic {
		h2 {
			color: @purple-button;
		}
		.arrow-list {
			li {
				color: @purple-button;
			}
		}
	}
	.logo-message {
		h2 {
			color: @purple-button;
		}
	}
	.desktop-and-smaller({
		header {
			background-color: @purple-footer;
		}
	});
}

.page-aviation {
	.team-members {
		.team-cell {
			.info {
				background-color: fade(@blue-aeroclub, 70%);
			}
		}
	}	
}

.page-aeroclub {
	header {
		background-color: fade(@blue-aeroclub, 70%);
	}
	.desktop({
		.menu {
			& > li {
				ul {
					li {
						background-color: fade(@blue-aeroclub, 70%);
						&:hover {
							// background-color: @tan-button;
						}
					}
				}
			}
		}
	});
	footer {
		background-color: @blue-aeroclub;
	}
	.team-members {
		.team-cell {
			.info {
				background-color: fade(@blue-aeroclub, 70%);
			}
		}
	}	
	.desktop-and-smaller({
		header {
			background-color: @blue-aeroclub;
		}
	});
}

.page-pilot-academy {
	header {
		background-color: fade(@blue-pilot-academy, 70%);
	}
	.desktop({
		.menu {
			& > li {
				ul {
					li {
						background-color: fade(@blue-pilot-academy, 70%);
						&:hover {
							// background-color: @tan-button;
						}
					}
				}
			}
		}
	});
	footer {
		background-color: @blue-pilot-academy;
	}
	.team-members {
		.team-cell {
			.info {
				background-color: fade(@blue-pilot-academy, 70%);
			}
		}
	}	
	
	.desktop-and-smaller({
		header {
			background-color: @blue-pilot-academy;
		}
	});
}

.std-txt {
	h1 {
		.grave(29, 600);
		text-transform: uppercase;
		margin-top: 0;
	}
	
	p {
		.open(16, 400);
	}
	ul {
		.chevron-list;
	}
}

.team-show {
	padding-top: 60px;
	padding-bottom: 60px;

	
	p {
		max-width: 1100px;
	}
	
	.max-800 {
		margin-bottom: 60px;
	}
}

.our-cirrus-fleet {
	margin-top: 60px;
	.msg-header {
		max-width: 950px;
		text-align: center;
	}
	
	.planes {
		img {
			width: 100%;
		}
	}
	// .plane-types {
	// 	background-color: @grey-box-back;
	// 	padding-bottom: 60px;
		
	// 	.container {
	// 		display: grid;
	// 		grid-template-columns: repeat(3, 1fr);
	// 		column-gap: 30px;
			
	// 		text-align: center;
	// 	}
		
	// 	.desc {
	// 		h2 {
	// 			.grave(29, 600);
	// 			text-transform: uppercase;
	// 		}
	// 	}
	// }
	
	.dynamic-plane-types {
		padding-bottom: 60px;
		overflow: hidden;
		position: relative;

		
		.desc {
			h2 {
				.grave(29, 600);
				text-transform: uppercase;
			}
		}
		.plane {
			text-align: center;
			margin-left: 10px;
			margin-right: 10px;
			
			h2 {
				font-size: 23pt;
				margin-bottom: 0;
			}
			h3 {
				margin-top: 0;
				font-size: 14pt;

			}
			img {
				max-width: 100%;
			}
			
		}
		
		.desktop-and-smaller({
			.plane {
				margin: 30px auto;
				max-width: 400px;
			}
		});
		
		.desktop({
			@img-height: 458px;
			.container {
				display: flex;
			}
			.plane {
				.pic {
					height: 220px;
					display: grid;
					align-items: center;
					img {
						height: @img-height;
						width: auto;
						max-height: 220px;
						max-width: 100%;
					}
				}
				.desc {
					padding-top: 15px;
				}
				
			}
			
			.plane:first-child {
				.desc {
					// position: relative;
					
					&:before {
						content: '\00a0';
						position: absolute;
						left: 0;
						right: 0;
						height: 600px;
						background-color: @grey-box-back;
						z-index: -1
					}
				}
			}

		});
	}
}

.schedule-a-tour {
	.aero-club-tour-stripe-picture {
		img {
			left: 0;
		}
	}
	.container {
		max-width: 770px;
		text-align: center;
		padding-top: 70px;
		padding-bottom: 50px;

		h2:first-child {
			margin-top: 0;
		}

		p, h2, h1, h3 {
			color: white;
		}
		h2 {
			.grave(29, 600);
			text-transform: uppercase;
		}
		h3 {
			.open(20, 800);
			margin-bottom: 40px;
			text-transform: uppercase;
		}
	}
}

.rate-sheet-bar {
	max-width: 800px;
	color: white;
	padding-top: 60px;
	padding-bottom: 60px;
	.fee-parts {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	
	h1 {
		.grave(29, 600);
		color: white;
		text-transform: uppercase;
		text-align: center;
	}

	h2 {
		.open(20, 800);
		color: white;
		text-transform: uppercase;
	}
	p {
		text-align: center;
		color: white;
		.open(16);
	}
}

.instructors-bar {
	max-width: 800px;
	color: @blue-text;
	padding-top: 60px;
	padding-bottom: 60px;
	.fee-parts {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	
	h1 {
		.grave(29, 600);
		text-transform: uppercase;
		text-align: center;
	}

	h2 {
		.open(20, 800);
		text-transform: uppercase;
	}
	p {
		text-align: center;
		.open(16);
	}
}

.rates-and-fees {
	color: @blue-text;
	padding-top: 20px;
	padding-bottom: 60px;
	.fee-parts {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	
	h1 {
		.grave(29, 600);
		text-transform: uppercase;
		text-align: center;
	}

	h2 {
		.open(20, 800);
		text-transform: uppercase;
	}
	p {
		.open(16);
	}
}

.maint-rates-and-fees {
	h1 {
		.grave(29, 600);
		text-transform: uppercase;
	}
	h2 {
		.open(20, 800) !important;
		margin-bottom: 0;
	}
	h3 {
		.open(18, 600);
		margin-top: 0;
	}
	p {
		.open(14);
	}
	
}

.amenities {
	padding-top: 60px;
	padding-bottom: 60px;
	h2 {
		.grave(29, 600);
		text-align: center;
		text-transform: uppercase;
	}
}

.pricing {
	padding-top: 60px;
	padding-bottom: 60px;
	max-width: 950px;
	h2 {
		.grave(29, 600);
		text-transform: uppercase;
	}
}

.topo-lines {
	stroke: #eff0f1;
	img, svg {
		opacity: .2;

	}
}

.news-item {
	.container {
		padding-top: 80px;
	}
	.author {
		text-transform: uppercase;
		.open(16, 600);
	}
	.date {
		color: @tan;
		text-transform: uppercase;
		.open(16, 600);
	}
	
	h2.title {
		.grave(26, 600);
		text-transform: uppercase;
		line-height: 1.1em;
		margin-top: 20px;
		margin-bottom: 0;
	}
}

.single-message {
	.container {
		min-height: 60vh;
		min-height: ~"calc(83px + 60vh)";
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.msg {
		text-align: center;
		
		h1, h2 {
			margin-top: 0;
			margin-bottom: 0;
		}
		
		h1 {
			.grave(60);
		}
		h2 {
			.open(25, 600);
			text-transform: uppercase;
		}
	}
	
}