@import (reference) "definitions";

.color-button {
	.grave(13);
	display: inline-block;
	line-height: 0em;

	text-transform: uppercase;
	color: white;
	text-decoration: none;
	background-color: @blue-button;
	
	border: 0;
	border-radius: 6px;
	
	text-align: center;
	padding: 21px 27px;
	
	
	&.dark-blue {
		background-color: @blue-aeroclub;
	}
	&.blue {
		background-color: @blue-button;
	}
	&.red {
		background-color: @red-button;
	}
	&.tan {
		background-color: @tan-button;
	}
	&.grey {
		background-color: @grey-button;
	}
	&.purple {
		background-color: @purple-button;
	}
	&.hollow {
		background-color: transparent;
		border: solid 2px white;
	}
	&.white {
		background-color: white;
		color: @blue-pilot-academy;
	}
	&.white-and-dark-blue {
		background-color: white;
		color: @blue-aeroclub;
	}
	
	&.spaced {
		margin-top: 2em;
	}
	
	& + & {
		margin-left: 20px;
	}

}


.pic-headline {
	font-size: 0;
	text-decoration: none;
}

.msg-and-pic {
	display: grid;
	.msg {
		display: grid;
		& > picture {
			grid-row: 1;
			grid-column: 1;
		}

		.inner {
			padding: 20px 40px;
			max-width: 700px;
			grid-row: 1;
			grid-column: 1;
			align-self: center;
			justify-self: center;
			img {
				max-width: 100%;
			}
		}
		
		p {
			.myriad(14);
		}
		
		&.grey {
			background-color: @grey-box-back;
		}

	}

	.pic {
		overflow: hidden;
		img {
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
			object-position: center center;
		}
		.desktop({
			img {
				min-width: 100%;
				min-height: 100%;
			}
		});

		&.para {
			picture {
				display: block;
				width: 100%;
				position: relative;
				min-height: 100%;
				overflow: hidden;
			}
			img {
				top: 0;
				left: 0;
				width: 100%;
				height: 100% !important;
			}
		}
	}
	
	&.blue {
		h2 {
			color: white;
		}
		p {
			color: white;
		}
		
	}
	.desktop({
		grid-template-columns: 1fr 1fr;
	});
	.desktop-and-smaller({
		.msg {
			h1 {
				font-size: 8vw;
			}
			.inner {
				padding-top: 80px;
				padding-bottom: 80px;
			}
		}
		.msg {
			grid-row: 1;
		}
		.pic {
			grid-row: 2;
		}

	});
}

.stay-connected {
	color: white;
	background-color: @tan;
	fill: white;

	&.use-picture-as-background > picture:first-child img {
	}
	
	.container {
		// position: relative;
		// z-index: 2;
		display: grid;
		row-gap: 40px;
		padding-top: 100px;
		padding-bottom: 100px;
		.desktop({
			grid-template-columns: 1fr 1fr;
			column-gap: 150px;
		});
		img {
			display: block;
			margin-top: 50px;
			max-width: 100%;
		}
		
		h1 {
			.grave(29);
			margin-top: 0;
		}
		p {
			.myriad(13);
			line-height: 2em;
		}
	}
	
	.desktop-and-smaller({
		.msg {
			text-align: center;
			img {
				display: block;
				margin: auto;
			}
		}

	});
	
}

.line-form {
	color: white;
	
	label {
		display: block;
		margin-bottom: 35px;
	}

	input[type="text"] {
		display: block;
		width: 100%;
		box-sizing: border-box;
		margin-top: 6px;
		background: transparent;
		border: 0;
		border-bottom: solid 1px white;
		
		&:focus {
			border: 0;
			outline: 0;
			border-bottom: solid 1px white;
		}
	}
	
	button {
		margin-top: 30px;
	}
}

.button-header {
	display: grid;
	row-gap: 30px;
	.tablet({
		grid-template-columns: 1fr auto;
	});
	
	.color-button {
		.grave(13);
	}
}


.logo-message {
	background-color: @grey-box-back;
	.container {
		display: grid;
		
		.desktop({
			grid-template-columns: minmax(400px, 60fr) auto;
		});
		column-gap: 60px;
		row-gap: 40px;
		padding-top: 80px;
		padding-bottom: 80px;
		
		.msg {
			h2 {
				.grave(28, 600);
				text-transform: uppercase;
				margin-top: 0;
			}
			
			p {
				.open(16, 400);
			}
		}

		.logo {
			align-self: center;
			
			img {
				display: block;
				max-width: 100%;
				margin: auto;
			}
			
			.desktop-and-smaller({
				img {
					width: 300px;
				}
			});
		}
	}
	

}

.team-members {
	display: grid;
	.big-phone({
		grid-template-columns: repeat(2, 1fr);
	});
	.desktop({
		grid-template-columns: repeat(3, 1fr);
	});
	column-gap: 30px;
	row-gap: 30px;
	
	.team-cell {
		aspect-ratio: .83;
		
		.info {
			display: grid;
			grid-template-columns: 1fr auto;
			// background-color: fade(@red-maint, 70%);
			padding: 10px 20px;
			height: 100%;
			box-sizing: border-box;
			
			top: 100%;
			position: relative;
			
			transition: top .3s, margin-top .3s;
			
			.ident {
				.name {
					.grave(21, 600);
					color: white;
					text-transform: uppercase;
				}
				.role {
					.open(13, 600);
					color: white;
				}
			}
			
			.up {
				padding-top: 12px;
				cursor: pointer;
				img {
					transition: transform .3s;
				}
			}
			
			.desc {
				grid-column: span 2;
				p {
					.open(13);
					color: white;
				}
			}
			
			&.full {
				margin-top: 0;
				top: 0;
				
				.up {
					img {
						transform: rotate(180deg);
					}
				}
			}
			

		}
		&:hover {
			.info {
				margin-top: -90px;

				&.full {
					margin-top: 0;
				}
			}
		}
	}
}

.chevron-list {
	list-style: none;
	padding-left: 24px;
	
	li {
		.grave(16, 600);
		margin-bottom: 10px;
		text-transform: uppercase;
		
		.light {
			.open(16, 400);
			text-transform: none;
		}
	}

	li:before {
		content: url('/img/icons/chevron-right.svg');
		display: inline-block;
		vertical-align: middle;
		margin-left: -29px;
		margin-right: 0px;
		margin-bottom: -4px;
	}
}

.chevron-list + p {
	margin-top: 50px;
}

.check-center-list {
	ul {
		list-style: none;
		padding-left: 24px;
		
		li {
			.open(16, 400);
			margin-bottom: 10px;
			text-align: center;
		}

		li:before {
			content: url('/img/icons/check-grey.svg');
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			vertical-align: middle;
			margin-left: -24px;
			margin-right: 10px;
		}
	}
}
.arrow-list {
	list-style: none;
	padding-left: 24px;
	
	li {
		.open(16, 800);
		margin-bottom: 10px;
		color: @blue-text;
		text-transform: uppercase;
		
	}

	li:before {
		content: url('/svg/arrow-forward-white.svg');
		display: inline-block;
		width: 20px;
		height: 20px;
		background-color: @grey-button;
		border-radius: 50%;
		vertical-align: middle;
		margin-left: -24px;
		margin-right: 10px;
	}
	
	&.light {
		li {
			.open(16);
			text-transform: none;
		}
	}
}

.left-headline {
	.container {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	h2 {
		text-transform: uppercase;
	}
}

.centered-headline {
	.container {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	h2 {
		text-align: center;
	}
}

.centered-intro {
	.intro-section;
	h1, h2, p {
		text-align: center;
	}

}

.transition-delay-for-el(@pos, @del) {
	&:nth-child(@{pos}) {
		transition-delay: @del;
	}
}

.benefit-boxes {
	display: grid;
	.desktop({
		grid-template-columns: repeat(3, 1fr);
	});
	&.visible {
		.box {
			transition: transform .6s, opacity .3s;
			.transition-delay-for-el(2, .2s);
			.transition-delay-for-el(3, .6s);
			.transition-delay-for-el(4, 1s);
			.transition-delay-for-el(5, 1.4s);
			.transition-delay-for-el(6, 1.8s);
			.transition-delay-for-el(7, 2.2s);
			.transition-delay-for-el(8, 2.6s);
			transform: translateY(0);
			opacity: 1;
		}
	}
	.box {
		background-color: @grey-box-back;
		transform: translateY(30px);
		opacity: 0;
		img {
			width: 100%;
			height: auto !important;
			aspect-ratio: 2;
			transition: transform .3s;
		}
		.pic {
			aspect-ratio: 2.1;
		}

		.msg {
			text-align: center;
			padding: 5px 20px 40px 20px;

			h2 {
				.grave(29, 600);
				text-transform: uppercase;
				color: @blue-text;
				margin-bottom: 0;
				line-height: 1.1em;
			}
			h3 {
				.open(16, 600);
				margin-top: 0;
			}
			p {
				.open(13);
			}
		}
		&:nth-child(2n) {
			background-color: white;
		}
		
		.center-msg {
			height: 100%;
			display: flex;
			align-items: center;
			justify-items: center;
			color: white;
			text-align: center;
			padding: 20px;
			max-width: 600px;
			margin: auto;

			h2 {
				.grave(29, 600);
				margin-top: 0;
			}
			p {
				.open(16, 400);
			}
			h3 {
				.open(22, 400);
			}
		}

		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	.center-msg-box {
	}

}

.services-boxes {
	.benefit-boxes;
	grid-template-columns: repeat(4, 1fr);
	

}

.icon-cells {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	text-align: center;
	
	.cell {
		.msg {
			.open(20, 800);
			margin-top: 20px;
		}
	}
}

.icons-on-left {
	display: grid;
	column-gap: 30px;
	row-gap: 30px;

	.msg {
		.open(16, 400);
	}
	
	.tablet({
		grid-template-columns: auto 1fr;
		
	});
	.tablet-and-smaller({
		.pic {
			text-align: center;
		}

	});
}

.intro-section {
	
	.container {
		padding-top: 90px;
		padding-bottom: 60px;
		& > h1:first-child {
			margin-top: 0;
		}
	}
}

.gp-form {
	form {
		@check-width: 30px;
		display: grid;
		column-gap: 30px;
		row-gap: 30px;
		
		input[type=text], input[type=date], textarea {
			display: block;
			box-sizing: border-box;
			width: 100%;
			margin-top: 8px;
			padding: 10px 14px;
		}
		
		.label, label {
			.open(18, 600);
			display: block;
			
			.txt {
				font-weight: 400;
			}
		}
		
		input[type=checkbox], input[type=radio] {
			width: @check-width;
		}
		
		.check-other {
			padding-left: @check-width + 6; 
		}
		
		h2 {
			text-transform: uppercase;
		}

	}
	
	.desktop({
		grid-template-columns: repeat(1, 1fr);
		.double {
			grid-column: span 2;
		}
		

	});
}
.contact-us-form {
	.gp-form;
	padding-top: 80px;
	padding-bottom: 80px;
}

.apply-form {
	.gp-form;
	padding-top: 30px;
	padding-bottom: 80px;
}

.news-summary {
	display: grid;
	// overflow: hidden;
	.desktop({
		grid-template-columns: auto 1fr;

	});
	.desktop-and-smaller({
		margin: 40px auto;
		max-width: 500px;

	});
	
	.pic {
		// position: relative;
		overflow: hidden;
		& > picture:first-child {
			user-select: none;
			img {
				display: block;
				// position: absolute;
				width: 100%;
				// height: 100% !important;
				min-height: 100%;
				aspect-ratio: 1;
				left: 0;
				object-fit: cover;
				object-position: center center;
				z-index: -1;
				user-select: none;
			}
		}
	}
	.info {
		background-color: @grey-box-back;
		padding: 40px 65px 55px 65px;
		display: grid;
		min-height: 100%;
		box-sizing: border-box;
		grid-template-rows: 1fr auto;
		
		.author {
			text-transform: uppercase;
			.open(16, 600);
		}
		.date {
			color: @tan;
			text-transform: uppercase;
			.open(16, 600);
		}
		
		h2 {
			.grave(26, 600);
			text-transform: uppercase;
			line-height: 1.1em;
			margin-top: 20px;
			margin-bottom: 0;
		}
		
		.desc {
			p:first-child {
				margin-top: 0;
			}
		}
		p {
			.open(16);
		}
		
		.buttons {
			margin-top: 30px;
		}
		
		& > *:first-child {
			// margin-top: 45px
		}
		& > *:last-child {
			// margin-bottom: 45px
		}
	}
}


.news-articles {
	.news-summary {
		margin-bottom: 40px;
	}
}


.news-tile {
	.news-summary;
	
	text-decoration: none;
	grid-template-columns: 1fr 4fr !important;
	
	color: inherit;

	.pic {
		img {
			border-radius: 10px;

		}
	}
	.info {
		background-color: transparent;
		padding: 10px 20px 10px 40px;
		
		
		h2 {
			.grave(15);
		}
	}
}

.news-tiles {
	display: grid;
	column-gap: 40px;
	row-gap: 30px;
	
	
	.desktop({
		grid-template-columns: 1fr 1fr;
	});
	.desktop-and-smaller({
		.news-tile {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
		}

	});
}

.three-msg {
	background-color: @grey-box-back;
	padding-top: 20px;
	padding-bottom: 40px;
	
	.container {
		display: grid;
		column-gap: 40px;
		row-gap: 40px;
		.desktop({
			grid-template-columns: repeat(3, 1fr);
			max-width: 1500px;
		});
	}
	.msg {
		text-align: center;
		
		h2 {
			.grave(29, 600);
			text-transform: uppercase;
		}
		
		p {
			.open(16, 400);
		}

	}
}

dialog.public {
	border: 0;
	background-color: fade(@grey-box-back, 95%);
	text-align: center;
	padding: 1px 35px 20px 35px;
	width: 100%;
	max-width: 350px;

	h3 {
		.aviano(20, 600);
		text-transform: uppercase;
		margin-bottom: 0;
	}
	
	input {
		display: block;
		width: 100%;
		margin-top: 12px;
		box-sizing: border-box;
	}
	
	label {
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	menu {
		padding-left: 0;
	}

}


details {
	summary {
		.aviano(14);
	}
	summary:before {
		display: inline-block;
		content: '+';
		width: 20px;
	}
}
details[open] {
	summary:before {
		content: '-';
	}
}