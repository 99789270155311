.setup-width-definitions() {
	@container-width: 1200px;
	@tablet-width: 900px;
	@tablet-small-width: 716px;
	@big-phone-width: 650px;
	@phone-width: 550px;
	@iphone-4-landscape-width: 480px;
	@iphone-4-width: 320px;
	@page-side-padding: 10px;
	@outside-page-padding: 58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@open-sans-base-font-size: 9.84375pt;
@aviano-base-font-size: 10.155871886pt;
@grave-base-font-size: 10pt;
@myriad-base-font-size: 9.84375pt;


@blue-button: #0e76bc;
@blue-pilot-academy: #2072aa;
@blue-text: #28384a;
@blue-top-menu: #0f161e;
@blue-aeroclub: @blue-text;

@red-button: #88181b;
@red-maint: #871619;

@purple-button: #524380;
@purple-footer: #272363;

@tan-button: #aa7543;

@grey-box-back: #e7e9eb;
@grey-button: #939598;
@grey-body-text: #444444;

@tan: 			#aa7543;

@header-height: 			83px;

.aviano(@size, @weight: 400) {
	font-family: "aviano-flare", sans-serif;
	font-weight: @weight;
	font-style: normal;
	font-size: @aviano-base-font-size * @size / 10;
}

.open(@size, @weight: 400) {
	font-family: "Open Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: @weight;
	font-style: normal;
	font-variation-settings: "wdth" 100;
	font-size: @open-sans-base-font-size * @size / 10;
}

.myriad(@size, @weight: 400) {
	font-family: "Open Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: @weight;
	font-style: normal;
	font-variation-settings: "wdth" 100;
	font-size: @myriad-base-font-size * @size / 10;
}

.grave(@size, @weight: 600) {
	font-family: "gravesend-sans", sans-serif;
	font-weight: @weight;
	font-style: normal;
	font-size: @grave-base-font-size * @size / 10;
}


.menu-link() {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}


h1, h2, h3, h4, h5 {
	.link-inherit;
}

body {
	.open(12);
	color: @blue-text;
}
